body {
    font-family: 'Ubuntu', sans-serif;
    color:#fff;

    background:url(../images/page-bg.png);
}

.container {
max-width:1980px;
width:100%;
}

img {
    max-width:100%;
}

.bg-image {
    background:url(../images/page-bg.png);
    min-height:1113px;
    height:100%;
}

.page-content {
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
}

.page-wrapper {
    padding: 50px 30px 65px 30px;
}


    .page-wrapper .title {
        font-size: 25px;
        text-align: center;
        font-weight: 300;
        letter-spacing: 1.5px;
    }

    .page-wrapper .countdown {
        font-size: 40px;
        letter-spacing: 5px;
        font-weight: 500;
        opacity: 0.6;
        text-align: center;
        font-weight: 300;
        letter-spacing: 1.5px;
    }

.title-wrapper {
    position:relative;
}

    .title-wrapper .countdown {
        position: absolute;
        z-index: 999;
        width: 100%;
        top: -10px;
        
        right: 20px;
        text-align: right;
    }

.room-number h1 {
    letter-spacing: 8px;
    font-size: 80px;
    text-shadow: 1px 1px 10px #000000;
    margin-top: 15px;
}

.join-player, .ranking-player {
    background-color: #111111;
    opacity: 0.8;
    margin: 0 auto;
    width: 70%;
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
}
.ranking-player {
    margin-top: 23px;
}
    .join-player .player-title {
        font-size: 25px !important;
        font-weight: 400;
        text-align:center;
    }

    .join-player .columns, .ranking-player .columns {
        padding: .75rem;
        text-align: center;
        font-size:20px;
    }
    .ranking-player .columns {
       font-weight:500;
    }


.footer {
    bottom: 30px;
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 0 50px;
}

.current-ranking h1 {
    font-weight: 400;
    border-bottom: solid 3px rgba(255, 255, 255, 0.5);
    display: inline-block;
    padding: 0 20px 20px 20px;
}

.top-player-ranking {
    background-color: #111111;
    opacity: 0.8;
    margin: 0 auto;
    width: 70%;
    margin-top: 40px;
    padding: 25px;
    border-radius: 10px;
}

    .top-player-ranking .wrapper {
        width: 70%;
        margin: 0 auto;
    }

.top-ranking {
    padding: 10px 20px;
    font-size: 35px;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 15px;
    border: solid 2px rgba(255,255,255,0.8);
    margin: 15px 0;
}

    .top-ranking.top {
        background: url(../images/top-ranking-bg.png);
        border: none;
        background-size: cover;
        border: 2px solid transparent;
        /*border-image: conic-gradient(red, yellow, lime, aqua, blue, magenta, red) 0.5;
        border-radius:10px;*/
    }


    .top-ranking .score {
        font-style: italic;
        font-weight: 300;
        font-size: 30px;
        letter-spacing: 1.5px;
    }

.winner-bg-image {
    background: url(../images/page-bg.png);
    min-height: 1113px;
    height: 100%;
}

.winner-title {
    margin-top:80px;

}

.winner-ranking .top-ranking {
    padding: 50px 20px;
    font-size: 30px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    border: solid 2px rgba(255,255,255,0.8);
    margin: 15px 0;
}
    .winner-ranking .top-ranking.first p.name{
        margin-bottom:140px;
    }
    .winner-ranking .top-ranking.second p.name {
        margin-bottom: 90px;
    }
    .winner-ranking .top-ranking.third p.name {
        margin-bottom: 40px;
    }
    .winner-ranking .top-ranking.first p.point, .winner-ranking .top-ranking.first p.time,
    .winner-ranking .top-ranking.second p.point, .winner-ranking .top-ranking.second p.time,
    .winner-ranking .top-ranking.third p.point, .winner-ranking .top-ranking.third p.time {
        font-weight: 300;
        font-style: italic;
        margin-bottom: 20px;
    }
    .winner-ranking .top-ranking.first div.speed,
    .winner-ranking .top-ranking.second div.speed,
    .winner-ranking .top-ranking.third div.speed {
        opacity: 0.5;
        font-size: 20px;
    }

.rank-place {
    font-weight:500;
}

.rank-place.third {
    font-size: 35px;
}
.rank-place.second {
    font-size: 40px;
}
.rank-place.first {
    font-size: 50px;
}

.rank-place-table {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom:20px;
}

.rank-place-table .columns{
   display: table-cell;
    vertical-align: bottom;

}

.other-winner {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    margin: 10px;
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0;
    opacity:0.8;
}

    .other-winner .point, .other-winner .time {
        font-style:italic;
        font-weight:300;
    }

@media (max-width: 991px) {
    .join-player, .ranking-player, .top-player-ranking {
            width: 90%;
        }
        .top-ranking {
            font-size: 25px;
        }
            .top-ranking .score {
                font-size: 25px;
            }

        .current-ranking h1 {
            font-size:30px;
        }
        .top-player-ranking .wrapper {
            width: 100%;
        }
    .winner-bg-image {
        background: url(../images/page-bg.png);
        min-height: 1500px;
        height: 100%;
        background-size: cover;
    }

}

@media (max-width: 767px) {

    .title-wrapper .countdown {
        position: relative;
        text-align: center;
        right:0;

    }

    .join-player .columns, .ranking-player .columns {
        padding: .75rem;
        text-align: center;
        font-size: 14px;
    }

    .join-player, .ranking-player, .top-player-ranking {
        width: 100%;
    }

    .winner-title {
        display:none;
    }
    .winner-bg-image {
        background: url(../images/page-bg.png);
        min-height: 2000px;
        height: 100%;
        background-size: cover;
    }
    .winner-ranking .top-ranking {
        padding: 30px 10px;
        font-size: 20px;
    }
}