.App {
  /* background-color: black; */
  text-align: left;
  font-family: Arial;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
}

.App-header-frame {
  background-color: transparent;
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.App-header-start {
  background-color: hsla(220, 13%, 18%, 0.9);
  /* min-height: 80vh; */
  min-height: 45vh;
  width: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
  /* width: '50%'; */
  /* height: '80%'; */
}

.App-header-base {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
}

.App-link {
  color: #61dafb;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.buttonStyleDownloadExcel {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #3f51b5;
  border-width: 0px;
  text-align: center;
  padding-left: 29px;  
}

.buttonStyleDownloadExcel:hover {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #3750e0;
  border-width: 0px;
  text-align: center;
  padding-left: 29px;  
  cursor: pointer;
}

.buttonStyleDownloadExcel-event {
  width: 275px;
  /* width: 100%; */
  height: 40px;
  /* font-size: 1rem; */
  /* line-height: 1.5; */
  /* padding: .375rem .75rem; */
  border-radius: 5px;
  /* font-size: 15px; */
  color: #ffffff;
  background-color: #007bff;
  border-width: 1px;
  border-color: white;
  text-align: center;
  /* padding-left: 27px;   */
  vertical-align: middle;
}

.buttonStyleDownloadExcel-event:hover {
  width: 275px;
  /* width: 100%; */
  height: 40px;
  /* font-size: 1rem; */
  /* line-height: 1.5; */
  /* padding: .375rem .75rem; */
  border-radius: 5px;
  /* font-size: 15px; */
  color: #ffffff;
  background-color: #3750e0;
  border-width: 1px;
  border-color: white;
  text-align: center;
  /* padding-left: 27px;   */
  cursor: pointer;
}

.buttonStyleDownloadExcelSimple {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 10px;   */
}

.buttonStyleDownloadExcelSimple:hover {
  /* width: 215px; */
  width: 100%;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #3750e0;
  border-width: 0px;
  text-align: center;
  /* padding-left: 10px;   */
  cursor: pointer;
}

.btn-sort {
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  /* padding-bottom: 5px; */
}

.buttonStyleCloseUi {
  width: 115px;
  height: 65px;
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  background-color: #3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 29px;   */
}

.buttonStyleEnterAdminUi {
  width: 55px;
  height: 55px;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  background-color: #3f51b5;
  border-width: 0px;
  text-align: center;
  /* padding-left: 29px;   */
}

/* @group Blink - 2021.01.07 */
.blink {
	-webkit-animation: blink 2s linear infinite;
	-moz-animation: blink 2s linear infinite;
	-ms-animation: blink 2s linear infinite;
	-o-animation: blink 2s linear infinite;
	 animation: blink 2s linear infinite;
}
@-webkit-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0.5; }
	100% { opacity: 0.5; }
}
@-moz-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0.5; }
	100% { opacity: 0.5; }
}
@-ms-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0.5; }
	100% { opacity: 0.5; }
}
@-o-keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0.5; }
	100% { opacity: 0.5; }
}
@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 1; }
	50.01% { opacity: 0.5; }
	100% { opacity: 0.5; }
}
/* @end */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('./html/css/styles.css');
